.rcw-conversation-container.active {
  width: 95vw;
  max-width: 95vw;
  background-color: #F6F5F1;
  margin-left: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.rcw-response .rcw-message-text,
.rcw-client .rcw-message-text {
  max-width: inherit;
}

.rcw-client .rcw-message-text {
  background-color: #B5E3FA;
  font-family: lato,sans-serif;
}

.rcw-response .rcw-message-text {
  background-color: #efe4b8;
  font-family: Bitter,sans-serif;
}

.rcw-conversation-container .rcw-header {
  background-color: #07d638;
  font-family: Bitter,sans-serif;
}

.rcw-messages-container {
  background-color: #F6F5F1;
}

.rcw-conversation-container {
  border-radius: 10px;
  /*box-shadow: 0 2px 10px 1px #eacd95;*/
}

.rcw-conversation-container .rcw-close-button {
  background-color: #41e30b;
  visibility: hidden;
}